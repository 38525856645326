/*
 * Syntax highlighting
 */

.highlight {
  .c, .cm, .c1, .cs {
    opacity: 0.55;
  }

  .err {
    // Let's not style errors, they likely are on purpose
  }

  .nf {
    color: var(--hl-function);
  }

  .k, .kt {
    color: var(--hl-keyword);
    font-weight: bold;
  }

  .m, .mf, .mh, .mi, .mo, .il {
    color: var(--hl-litteral);
  }

  .kc, .nb, .nc, .ni, .ne, .nn, .nl, .nt {
    color: var(--hl-name);
  }

  .o, .ow {
    color: var(--hl-operator);
    font-weight: bold;
  }


  .cp {
    color: var(--hl-preprocessor);
  }

  .s, .sb, .sc, .sd, .s2, .se, .sh, .si, .sx, .sr, .s1, .ss {
    color: var(--hl-string);
  }

  .na, .no, .nv, .vc, .vg, .vi {
    color: var(--hl-variable);
  }

  // Unset values, displayed in magenta to easily detect them

  .gd    { color: magenta; } // Generic.Deleted
  .gd .x { color: magenta; } // Generic.Deleted.Specific
  .ge    { color: magenta; } // Generic.Emph
  .gr    { color: magenta; } // Generic.Error
  .gh    { color: magenta; } // Generic.Heading
  .gi    { color: magenta; } // Generic.Inserted
  .gi .x { color: magenta; } // Generic.Inserted.Specific
  .go    { color: magenta; } // Generic.Output
  .gp    { color: magenta; } // Generic.Prompt
  .gs    { color: magenta; } // Generic.Strong
  .gu    { color: magenta; } // Generic.Subheading
  .gt    { color: magenta; } // Generic.Traceback
  .kd    { color: magenta; } // Keyword.Declaration
  .kp    { color: magenta; } // Keyword.Pseudo
  .kr    { color: magenta; } // Keyword.Reserved
  .w     { color: magenta; } // Text.Whitespace
  .bp    { color: magenta; } // Name.Builtin.Pseudo
}
