// This describes how elements look, independently of their surroundings.

/*
 * Placeholder classes
 */

@mixin selection {
  ::selection {
    background: var(--accent-bg-color);
    color: var(--accent-fg-color);
  }
}

@mixin selection-accent {
  ::selection {
    background: var(--accent-fg-color);
    color: var(--accent-bg-color);
  }
}

@mixin accent-bg {
  background-color: var(--accent-bg-color);
  @include selection-accent;

  &, a, a:visited {
    color: var(--accent-fg-color);
  }
}

@mixin border {
  border: 0.04rem solid var(--border-color);
}

@mixin border-medium {
  border: 0.0625rem solid var(--border-color);
}

@mixin button {
  &, &:visited {
    border: 0.15em solid var(--accent-color);
    color: var(--accent-color);
    font-weight: 700;
  }

  &:hover {
    background: var(--accent-bg-color);
    border-color: var(--accent-bg-color);
    color: var(--accent-fg-color);
  }
}

@mixin card-shadow {
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.1), 0 0.3rem 1.5rem 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0.1rem 0 rgba(0, 0, 0, 0.1), 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.1);
}

@mixin card {
  @include card-shadow;

  background-color: var(--card-bg-color);
  overflow: hidden;

  footer {
    font-weight: 700;

    svg {
      height: 1em;
      width: 1em;
    }
  }
}

@mixin anchor-card-hover-highlight {
  background: var(--hover-card-bg-color);
  color: var(--hover-card-fg-color);
  border-color: var(--hover-card-border-color);

  > img,
  > video {
    border-color: var(--hover-card-border-color);
  }

  h1 {
    color: var(--accent-h1-color);
  }

  h2 {
    color: var(--accent-h2-color);
  }
}

@mixin anchor-card {
  @include card;

  &, &:visited {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    @include anchor-card-hover-highlight;
  }
}

@mixin circle {
  aspect-ratio: 1;
  border-radius: 100%;
  object-fit: cover;
}

@mixin dark-overlay {
  background: rgba(0, 0, 0, 0.4);
  text-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.2), 0 0.3rem 1.5rem rgba(0, 0, 0, 0.2);
}

@mixin dim {
  opacity: 0.55;
}

@mixin rounded {
  border-radius: 0.5rem;
}

@mixin rounded-tight {
  border-radius: 0.2rem;
}

@mixin tag {
  @include anchor-card;
  @include border;

  border-radius: 9999px;
  font-weight: 700;
  padding: 0.1em 0.6em;
  // On one hand breaking tags on whitespaces looks bad, on the other hand long
  // tags popping out of the pages or breaking a line very early looks even
  // worse. Ideally long tags should be avoided, in pratice… it's not always
  // possible, so they will exist.
  white-space: nowrap;

  &:hover {
    color: var(--accent-color);
  }
}

// This tag draws its pill in a smarter way that doesn't affect it's text's
// placement and that doesn't overlap previous text, but that's unstable whith
// an inline display where line-breaks in the tag are possible.
@mixin block-tag {
  font-weight: 700;
  display: inline-block;
  position: relative;
  text-indent: initial;

  &, &:visited {
    color: inherit;
    text-decoration: none;

    &::before {
      border-radius: 9999px;
      margin: -0.1em -0.6em;
      padding: 0.1em 0.6em;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }

  &:hover::before {
    @include card-shadow;
    @include anchor-card-hover-highlight;
    @include border;

    background: var(--hover-card-bg-color);
    color: var(--hover-card-fg-color);
    border-color: var(--hover-card-border-color);
  }
}

/*
 * Main structure elements
 */

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  @supports (font-variation-settings: normal) {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $variable-font-family;
  }
  font-kerning: normal;
  min-height: 100vh;
  -webkit-text-size-adjust: 100%;
  @include selection;

  font-size: $base-font-size;

  @include media-query($on-medium) {
    font-size: $medium-font-size;
  }

  @include media-query($on-small) {
    font-size: $small-font-size;
  }

  @include media-query($on-tiny) {
    font-size: $tiny-font-size;
  }
}

body > header {
  color: var(--accent-color);
  font-weight: 900;
  line-height: 1.125em;
  min-height: var(--site-header-min-height);

  a, a:visited {
    @include block-tag;

    color: var(--accent-color);
    font-weight: inherit;
    text-decoration: none;
  }

  h1 {
    color: var(--accent-h1-color);
    font-family: $heading-font-family;
    font-size: var(--h1-font-size);
    font-variation-settings: "wght" var(--h1-font-weight);
    font-weight: var(--h1-font-weight);
  }

  h2 {
    color: var(--accent-h2-color);
    font-family: $heading-font-family;
    font-variation-settings: "wght" var(--h2-font-weight);
    font-weight: var(--h2-font-weight);
  }

  @include media-query($on-tiny) {
    h2 {
      font-size: var(--h2-font-size);
    }
  }
}

body > main > article > header,
body > main > nav > header {
  > h1 {
    font-family: $heading-font-family;
    font-size: var(--h1-font-size);
    font-variation-settings: "wght" var(--h1-font-weight);
    font-weight: var(--h1-font-weight);
  }

  > h2 {
    font-family: $heading-font-family;
    font-size: var(--h2-font-size);
    font-variation-settings: "wght" var(--h2-font-weight);
    font-weight: var(--h2-font-weight);
  }

  > time {
    @include dim;
  }

  // hashtags
  > section > a {
    @include tag;

    &::before {
      content: "#";
    }
  }

  > img {
    @include border;
    @include card;
    @include rounded;

    aspect-ratio: 16/9;
    object-fit: cover;
  }
}

body > main > article > section,
body > main > article > section section,
body > main > article > section > blockquote,
body > main > article > section section > blockquote,
body > main > nav > section {
  > h1 {
    font-family: $heading-font-family;
    font-size: var(--h1-font-size);
    font-variation-settings: "wght" var(--h1-font-weight);
    font-weight: var(--h1-font-weight);

    > strong {
      color: var(--accent-h1-color);
    }
  }

  > h2 {
    font-family: $heading-font-family;
    font-size: var(--h2-font-size);
    font-variation-settings: "wght" var(--h2-font-weight);
    font-weight: var(--h2-font-weight);

    > strong {
      color: var(--accent-h2-color);
    }
  }

  > h3, > h4, > h5, > h6,
  figcaption {
    font-family: $heading-font-family;
    font-size: var(--h3-font-size);
    font-variation-settings: "wght" var(--h3-font-weight);
    font-weight: var(--h3-font-weight);
  }

  > p {
    text-indent: var(--text-indent);
  }

  p, ul, ol {
    em {
      font-style: italic;
    }

    strong {
      font-weight: 700;
    }

    a, a:visited {
      color: var(--text-color);

      &:hover {
        color: var(--accent-color);
      }
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  hr {
    border: none;
    border-top: 0.0625rem solid var(--text-color);
  }

  sup {
    vertical-align: super;
    font-size: 0.625em;
  }

  > div.button > a.button {
    @include tag;

    padding: 1.25rem 2.5rem;

    svg {
      height: 1em;
      width: 1em;
    }
  }

  > a.card {
    @include anchor-card;
    @include border;
    @include rounded;

    img {
      @include border;
      @include rounded-tight;

      object-fit: cover;
    }

    h2 {
      font-family: $heading-font-family;
      font-size: var(--h2-font-size);
      font-variation-settings: "wght" var(--h2-font-weight);
      font-weight: var(--h2-font-weight);
    }

    > time {
      @include dim;
    }
  }

  > table {
    @include card;
    @include rounded;

    overflow-x: auto;
    scrollbar-width: none;
  }

  div.footnotes {
    font-size: 0.625em;
  }
}

body > footer {
  color: var(--accent-color);
  font-weight: 700;

  > footer {
    font-weight: 900;
  }

  h1 {
    color: var(--accent-h1-color);
    font-family: $heading-font-family;
    font-size: var(--h1-font-size);
    font-variation-settings: "wght" var(--h1-font-weight);
    font-weight: var(--h1-font-weight);
  }

  h2 {
    color: var(--accent-h2-color);
    font-family: $heading-font-family;
    font-size: var(--h2-font-size);
    font-variation-settings: "wght" var(--h2-font-weight);
    font-weight: var(--h2-font-weight);
  }

  a, a:visited {
    @include block-tag;

    color: var(--accent-color);
    font-weight: 700;
    text-decoration: none;
  }
}

/*
 * Elements
 */

blockquote {
  border-left: 0.125rem solid var(--accent-bg-color);
  padding-left: 1rem;
}

code {
  font-family: monospace;
  line-height: 1;
  text-align: left;
}

figure {
  @include rounded;

  &:not(.sponsorship-badge) {
    @include border;
    @include card;

    img,
    video {
      @include border;
      @include rounded-tight;
    }
  }

  &.sponsorship-badge {
    img {
      @include border;
      @include card;
      @include circle;

      width: 12rem;
    }
  }
}

img,
video {
  vertical-align: middle;
  width: 100%;

  &.emote {
    vertical-align: baseline;
    width: 1em;
  }
}

div.highlight {
  @include card;
  @include rounded;
}

figure.scrolled-window,
div.highlight {
  overflow: hidden;
  overflow-x: auto;

  > .viewport,
  > pre {
    width: max-content;
    align-self: start;
  }

  > figcaption {
    position: sticky;
    left: 0;
  }
}

svg {
  fill: currentColor;
}

table {
  &:not(:first-child) {
    margin-top: 1em;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }

  th {
    font-weight: 700;
  }

  td {
    text-align: left;
    max-inline-size: 18ch;
    white-space: unset;
    text-wrap: balance;
  }

  td, th {
    @include border-medium;

    padding: 0.25em 0.5em;
  }
}

/*
 * Classes
 */

.project {
  > a {
    @include anchor-card;
  }

  > div {
    @include card;
  }

  > a, > div {
    @include border;
    @include rounded;

    > header {
      > picture {
        filter: drop-shadow(0 0.08rem 0.1rem rgba(0, 0, 0, 0.2)) drop-shadow(0 0.4rem 0.7rem rgba(0, 0, 0, 0.12));
      }

      > h2 {
        font-family: $heading-font-family;
        font-size: var(--h2-font-size);
        font-variation-settings: "wght" var(--h2-font-weight);
        font-weight: var(--h2-font-weight);
      }

      > h3 {
        font-weight: 700;
      }

      > .date {
        @include dim;
      }
    }
  }
}

