// This describes how elements are laid out and spaced.

/*
 * Placeholder classes
 */

@mixin column {
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
}

@mixin row-gap {
  row-gap: var(--row-gap);
}

@mixin centered {
  align-items: center;
  text-align: center;
}

@mixin isolated {
  margin: 1.25rem 2.5rem;

  @include media-query($on-medium) {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  @include media-query($on-small) {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    margin-left: 0;
    margin-right: 0;
  }
}

@mixin rounded-padding {
  padding: 1.5rem;

  @include media-query($on-medium) {
    padding: 1.5rem 1rem;
  }

  @include media-query($on-small) {
    padding: 1.5rem 0.5rem;
  }
}

@mixin row {
  align-items: center;
  column-gap: 0.5em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  line-height: 1;
}

@mixin row-to-column-on-tiny {
  @include media-query($on-tiny) {
    flex-direction: column;
    row-gap: 0.75rem;
  }
}

@mixin center-column-on-tiny {
  @include media-query($on-tiny) {
    align-items: center;
    text-align: center;
  }
}

@mixin wrapper {
  align-self: center;
  max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
  width: calc(100% - (#{$spacing-unit} * 2));

  @include media-query($on-medium) {
    width: calc(100% - (#{$spacing-unit} * 2 / 2));
  }
}

/*
 * Main structure elements
 */

body,
main,
main > *,
main > * > header {
  @include column;
}

body,
body > main,
body > main > article,
body > main > nav {
  row-gap: 4.5rem;
}

body > header {
  @include row;
  @include wrapper;

  > nav {
    @include center-column-on-tiny;
    @include row;
    @include row-to-column-on-tiny;

    align-items: first baseline;
    column-gap: 1.5rem;
    padding: 1rem 0;
    width: 100%;

    h1 {
      flex-grow: 1;
    }

    @include media-query($on-tiny) {
      align-items: center;
    }
  }
}

body > main {
  @include wrapper;
}

body > main > article > header,
body > main > nav > header {
  @include column;
  @include row-gap;

  > section {
    @include row-gap;

    display: flex;
    flex-flow: row wrap;
    column-gap: 0.5rem;

    > a {
      display: inline-block;
      overflow: none;
    }
  }
}

// Only add a row gap to non-nested sections
body > main > article > section,
body > main > article > section > blockquote,
body > main > article > section > div.footnotes,
body > main > nav > section {
  @include row-gap;
}

body > main > article > section,
body > main > article > section section,
body > main > article > section > blockquote,
body > main > article > section section > blockquote,
body > main > article > section > div.footnotes,
body > main > nav > section {
  @include column;

  > h2, > h3, > h4, > h5, > h6 {
    margin-top: var(--h2-margin-top);
  }

  div.button {
    @include isolated;

    align-self: center;

    > a.button {
      @include row;
    }
  }

  > a.card {
    @include column;
    @include isolated;
    @include rounded-padding;

    > :not(time):not(:first-child),
    > :not(h2) ~ time {
      margin-top: 1.5rem;
    }

    > h2 ~ time {
      margin-top: 0.5rem;
    }

    img {
      aspect-ratio: 5/2;
    }

    svg {
      display: block;
      height: 1.25em;
      width: 1.25em;
    }

    p {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }

    > footer {
      align-items: center;
      align-self: center;
      column-gap: 0.5em;
      display: flex;
      flex-flow: row nowrap;
    }
  }

  > picture {
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
  }

  ul, ol {
    list-style-position: outside;
    padding-left: 1rem;

    li {
      padding-inline-start: 1rem;
    }
  }

  hr {
    width: calc(100% - 2 * 1em);
    align-self: center;
    margin-top: var(--h2-margin-top);
    margin-bottom: var(--h2-margin-top);
  }

  @include media-query($on-medium) {
    ul, ol {
      list-style-position: outside;
      padding-left: 0.5rem;

      li {
        padding-inline-start: 0.5rem;
      }
    }
  }

  sup > a.footnote {
    margin-left: 0.1em;
    margin-right: 0.1em;
  }
}

body > footer {
  @include column;
  @include wrapper;

  margin-bottom: 1.5rem;

  > address {
    @include row;
    @include row-to-column-on-tiny;

    align-items: start;
    gap: 0.75rem 1.5rem;
    justify-content: space-between;

    > section {
      @include column;
      @include row-gap;

      align-items: start;

      > h2 {
        margin-top: var(--h2-margin-top);
      }

      > a {
        @include row;
        @include row-gap;
      }
    }
  }

  > footer {
    @include row;

    justify-content: center;
    margin-top: 4.5rem;

    > div {
      @include row;
    }
  }

  svg {
    display: block;
    height: 1.25em;
    width: 1.25em;
  }
}

/*
 * Elements
 */

figure {
  @include column;
  @include centered;
  @include isolated;
  @include rounded-padding;
  @include row-gap;

  &.sponsorship-badge {
    // Undo the parent figure's %rounded's padding, it's not needed as we don't
    // show a card.
    padding-bottom: 0;
    padding-top: 0;
  }

  > img,
  > video {
    display: block;
  }
}

div.highlight {
  @include isolated;
  @include rounded-padding;

  display: grid;
}

/*
 * Classes
 */

.project > a,
.project > div {
  @include column;
  @include isolated;
  @include rounded-padding;

  row-gap: 1.5rem;

  > header {
    align-items: end;
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem 1.5rem;
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "role"
      "date";
    grid-template-rows: 1fr min-content min-content;

    &.icon {
      grid-template-areas:
        "title icon"
        "role icon"
        "date icon";
      grid-template-columns: 1fr 5rem;
    }

    > picture {
      grid-area: icon;
    }

    > h2 {
      grid-area: title;
      // Undo h2's margin-top
      margin-top: 0;
    }

    > h3 {
      grid-area: role;
      // Undo h3's margin-top
      margin-top: 0;
    }

    > .date {
      grid-area: date;
    }
  }

  > footer {
    align-items: center;
    align-self: center;
    column-gap: 0.5em;
    display: flex;
    flex-flow: row nowrap;
  }
}

