@font-face {
  font-family: "Cantarell var";
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("Cantarell-VF.woff2") format("woff2"),
       url("Cantarell-VF.otf") format("opentype");
}

$styles: (normal: "", italic: "-Italic");

@each $style-value, $style-name in $styles {
  @font-face {
    font-family: InterVariable;
    font-style: #{$style-value};
    font-weight: 100 900;
    font-display: swap;
    src: url("fonts/inter/InterVariable#{$style-name}.woff2") format("woff2"),
         url("fonts/inter/InterVariable#{$style-name}.otf") format("opentype");
  }
}

$families: Inter, InterDisplay;
$weights: (100: "Thin", 200: "ExtraLight", 300: "Light", 400: "Regular", 500: "Medium", 600: "SemiBold", 700: "Bold", 800: "ExtraBold", 900: "Black");
$styles: (normal: "", italic: "Italic");

@each $family in $families {
  @each $weight-value, $weight-name in $weights {
    @each $style-value, $style-name in $styles {
      @if $weight-name == "Regular" and $style-name == "Italic" {
        $weight-name: "";
      }

      @font-face {
        font-family: $family;
        font-style: $style-value;
        font-weight: $weight-value;
        font-display: swap;
        src: url("fonts/inter/#{$family}-#{$weight-name}#{$style-name}.woff2") format("woff2"),
             url("fonts/inter/#{$family}-#{$weight-name}#{$style-name}.otf") format("opentype");
      }
    }
  }
}

