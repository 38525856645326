@charset "utf-8";

@import "fonts";

// Define defaults for each variable.

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
    font-optical-sizing: auto;
  }
}

$base-font-family: Inter, sans-serif, !default;
$variable-font-family: InterVariable, sans-serif, !default;
$heading-font-family: InterDisplay, sans-serif, !default;
$base-font-weight: 300 !default;
$base-line-height: 1.5 !default;

$base-font-size:   16pt !default;
$medium-font-size: 14pt !default;
$small-font-size:  12pt !default;
// On tiny we don't change the font size, only the layout
$tiny-font-size:   12pt !default;

$spacing-unit:     1.5rem !default;

// Width of the content area
$content-width:    36rem !default;

// Check if we can't fit the expected number of columns for the superior font size.
$on-medium:        36px * 18 / 3 * 4 !default;
$on-small:         36px * 16 / 3 * 4 !default;
$on-tiny:          36px * 14 / 3 * 4 !default;

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

// Import partials.
@import "layout";
@import "appearance";
@import "syntax-highlighting";

:root {
  --accent-hue: 155;
  --normal-hue: calc(var(--accent-hue) - 120);

  --accent-bg-color: hsl(var(--accent-hue), 62%, 39%);
  --accent-h1-color: hsl(var(--accent-hue), 100%, 26%);
  --accent-h2-color: hsl(var(--accent-hue), 100%, 24%);
  --accent-color: hsl(var(--accent-hue), 100%, 22%);
  --accent-fg-color: hsl(var(--normal-hue), 0%, 99%);
  --background-color: hsl(var(--normal-hue), 0%, 99%);
  --border-color: hsl(0, 0%, 91%);
  --card-bg-color: #fff;
  --hover-card-bg-color: hsl(var(--accent-hue), 30%, 97%);
  --hover-card-border-color: hsl(var(--accent-hue), 35%, 90%);
  --hover-card-fg-color: var(--accent-color);
  --text-color: hsl(0, 0%, 7%);

  --hl-function: #4186A8;
  --hl-keyword: #008080;
  --hl-litteral: #099;
  --hl-name: #0077AA;
  --hl-operator: #C00;
  --hl-preprocessor: #B58900;
  --hl-string: #690;
  --hl-variable: #008080;
  --site-header-min-height: 4rem;
  --h1-font-size: 1.875rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --h1-font-weight: 900;
  --h2-font-weight: 700;
  --h3-font-weight: 700;
  --h2-margin-top: 1.25rem;

  --text-indent: 1em;
  --row-gap: 0.75rem;

  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  font-feature-settings: 'liga' 1, 'calt' 1; /* fix for Chrome */

  @include media-query($on-medium) {
    font-size: $medium-font-size;
  }

  @include media-query($on-small) {
    font-size: $small-font-size;
  }

  @include media-query($on-tiny) {
    font-size: $tiny-font-size;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --accent-hue: 167;

    --accent-bg-color: hsl(167, 57%, 28%);
    --accent-h1-color: hsl(var(--accent-hue), 54%, 52%);
    --accent-h2-color: hsl(var(--accent-hue), 54%, 60%);
    --accent-color: hsl(var(--accent-hue), 54%, 68%);
    --accent-fg-color: #fdfdfd;
    --background-color: hsl(var(--normal-hue), 0%, 13%);
    --border-color: hsl(0, 0%, 9%);
    --card-bg-color: #333;
    --hover-card-bg-color: hsl(var(--accent-hue), 20%, 15%);
    --hover-card-border-color: hsl(var(--accent-hue), 35%, 8%);
    --hover-card-fg-color: var(--accent-color);
  --text-color: hsl(0, 0%, 93%);
  }
}

@supports (font-variation-settings: normal) {
  :root {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $variable-font-family;
    font-optical-sizing: auto;
  }
}

html {
  scroll-behavior: smooth;
}
